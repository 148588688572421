<template>
  <div class="dashboard-widget-settings">

    <el-form
      label-position="left"
      label-width="200px"
      style="width: 700px"
      size="mini"
    >

      <el-form-item label="Показывать легенду">
        <el-switch v-model="localValue.legend"></el-switch>
      </el-form-item>
      <el-form-item label="Отображение полукругом">
        <el-switch v-model="localValue.halfCircle"></el-switch>
      </el-form-item>
      <el-form-item label="Показывать подписи">
        <el-switch v-model="localValue.labelsX"></el-switch>
      </el-form-item>
      <el-form-item label="Подсказки при наведении">
        <el-switch v-model="localValue.tooltips"></el-switch>
      </el-form-item>
      <el-form-item label="Дистанция подписей">
        <el-slider v-model="localValue.labelDistance" :min="-100" :max="100" show-input input-size="mini"></el-slider>
      </el-form-item>
      <el-form-item label="Диаметр пустоты внутри">
        <el-slider v-model="localValue.innerSize" :min="0" :max="99" show-input input-size="mini"></el-slider>
      </el-form-item>

    </el-form>

  </div>
</template>

<script>
import Builder from "@/components/report/builder/Builder.vue";
import VQBForm from "@/components/report/builder/VQBForm.vue";

export default {
  name: 'VQBCellWidgetSettings',
  components: {VQBForm, Builder},
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    localValue: {
      get() {return this.value},
      set(value) {this.$emit('input', value)}
    }
  },
  data(){
    return {}
  },
  methods: {}
}
</script>